import { template as template_62cfc56a00e84f1da585d23052d8628e } from "@ember/template-compiler";
const FKControlMenuContainer = template_62cfc56a00e84f1da585d23052d8628e(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
