import { template as template_660c70954b944b2ca0768f0f07f41bee } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_660c70954b944b2ca0768f0f07f41bee(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
