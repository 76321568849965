import { template as template_743ea8350c9247829c17982e735c2755 } from "@ember/template-compiler";
const FKText = template_743ea8350c9247829c17982e735c2755(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
